var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("main", [_c("navigation"), _vm._m(0), _c("lioshutanFooter")], 1)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "section",
      {
        attrs: {
          id: "newFuture",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "content",
          },
          [
            _c(
              "div",
              {
                staticClass: "content-title",
              },
              [
                _c(
                  "h3",
                  {
                    staticClass: "category",
                  },
                  [_vm._v("六書堂創新科技VS敦煌經典教材 打造兒童英文新未來")]
                ),
                _c("div", {
                  staticClass: "divider",
                }),
                _c(
                  "p",
                  {
                    staticClass: "source",
                  },
                  [_vm._v("民眾日報")]
                ),
                _c(
                  "p",
                  {
                    staticClass: "source-date",
                  },
                  [_vm._v("2023年9月12日")]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "content-newsImg",
              },
              [
                _c("img", {
                  staticClass: "newsImg",
                  attrs: {
                    src: require("@/assets/collaboration-with-caves-education/news-img1.svg"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    "▲（圖／業者提供）《圖說》合作簽約儀式由敦煌集團語言學習事業處協理洪村裕（左二）與六書堂執行長吳政哲（右二）共同主持。"
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "content-text",
              },
              [
                _vm._v(
                  "【民眾網諸葛志一臺中報導】面對全球化時代，政府積極推動「2030雙語國家政策」，如何幫助學童突破缺乏實際應用、學習動機低落等困境，六書堂數位學習集團旗下兒童英文品牌 WUWOW JUNIOR 與敦煌教育集團，宣布合作攜手打造O2O教育新思維！雙方期待各自發揮所長，整合資源，提供更豐富、完整、有趣的英文學習方式，讓台灣學子不管在線上或實體都能夠快樂學習，建立堅實英語基礎。"
                ),
                _c("br"),
                _c("br"),
                _vm._v(
                  "合作簽約儀式11日舉行，由敦煌集團語言學習事業處協理洪村裕與六書堂執行長吳政哲共同主持。六書堂取得敦煌教育集團21st Century系列教材授權，內容與外師團隊共同編撰，特別結合108課綱核心素養，培養品德教育、家庭教育等內容，並在課後附上練習音檔及仿照劍橋兒童英檢題型，協助孩子們穩紮穩打學英文！而WUWOW JUNIOR為一線兒童線上英文平台，以AI智慧配對、遊戲化教學、即時互動等創新技術，讓孩子在快樂中自然學英語，目前已累計開課近15,000堂，獲得家長和孩子一致好評。"
                ),
                _c("br"),
                _c("br"),
                _vm._v(
                  "雙方將利用線上與線下活動相互搭配，強化學習體驗的成效，把線上學到的知識透過實體活動實際應用，把學習的「知道」強化到「做到」。例如：萬聖節期間，線上學習以萬聖節為主題的英文課程，結合線下實體美術手作課程，製作南瓜燈。讓學習過程更有趣好玩，並且達成英文複習、實際應用的成效！"
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "content-newsImg",
              },
              [
                _c("img", {
                  staticClass: "newsImg",
                  attrs: {
                    src: require("@/assets/collaboration-with-caves-education/news-img2.svg"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    "▲簽約儀式由敦煌集團語言學習事業處協理洪村裕，與六書堂CEO吳政哲簽署，未來雙方將利用線上與線下活動相互搭配，強化學習體驗的成效，（圖／業者提供）"
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "content-text",
              },
              [
                _vm._v(
                  "六書堂執行長吳政哲表示，敦煌教育集團深耕外語教育70年，很關注台灣的英文教育發展，對於台灣學生適合的教學內容、教學方法有深入研究，此次合作授權CAVES KIDS教材內容更是專門為「台灣學生」設計，更勝直接引進國外出版社的英文學習教材。WUWOW JUNIOR，將在教學中導入背單字、簽到、獎勵等各式系統服務，將學習轉為打怪破關的遊戲化形式，輔助學習。兩大教育集團的合作，可以向快樂學習更加靠攏，為英文教育發展注入新活水。"
                ),
                _c("br"),
                _c("br"),
                _vm._v(
                  "敦煌集團協理洪村裕表示，認同WUWOW JUNIOR 教學理念，線上學習與實體學習有互補性，且逐漸成為趨勢，因此非常樂見WUWOW JUNIOR 選擇 21st Century 系列教材作為線上課程的內容，希望藉線上線下整合，搶佔先機。另外，線上課程隨時隨地都可以學習、並與世界各地不同國籍的師資接觸，加上教育部也以「班班有網路，生生用平板」為口號，朝向數位學習方向邁進。"
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "content-newsImg",
              },
              [
                _c("img", {
                  staticClass: "newsImg",
                  attrs: {
                    src: require("@/assets/collaboration-with-caves-education/news-img3.svg"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    "▲《圖說》六書堂攜手敦煌集團，共同合作打造兒童英文新未來。"
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "news-source",
              },
              [
                _c("p", [_vm._v("新聞資料來源")]),
                _c(
                  "div",
                  {
                    staticClass: "news-source-url",
                  },
                  [
                    _vm._v("1. NOWnews："),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.nownews.com/news/6258820",
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "六書堂聯手敦煌教育集團 為兒童創造英文教學新天地"
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "news-source-url",
                  },
                  [
                    _vm._v("2. UDN聯合報："),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://udn.com/news/story/6885/7433006",
                          target: "_blank",
                        },
                      },
                      [_vm._v("六書堂取得敦煌線上授權 整合資源讓英文變好玩了")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "news-source-url",
                  },
                  [
                    _vm._v("3. 民眾日報（Yahoo）："),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://tw.news.yahoo.com/%E5%85%AD%E6%9B%B8%E5%A0%82%E5%89%B5%E6%96%B0%E7%A7%91%E6%8A%80vs%E6%95%A6%E7%85%8C%E7%B6%93%E5%85%B8%E6%95%99%E6%9D%90-%E6%89%93%E9%80%A0%E5%85%92%E7%AB%A5%E8%8B%B1%E6%96%87%E6%96%B0%E6%9C%AA%E4%BE%86-005806214.html",
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "六書堂創新科技VS敦煌經典教材 打造兒童英文新未來"
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }